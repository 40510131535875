<template>
  <article class='about'>
    <h1>About</h1>
    <p class='text-light'>
      This is a very simple Vue project which uses axios, bootstrap-vue, vue-router, three.js and animate.css to show my projects 
      in details
    </p>
  </article>
</template>

<script>
export default {
  name: "About"

}
</script>

<style scooped>
.about{
  margin-top: 50px;
  padding:10px;
}
</style>